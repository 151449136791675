import React from "react";
import {Text,Heading,Box, SimpleGrid,HStack} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import { motion } from "framer-motion";
import MySeo from  '../components/seo'

export default function Beitrag3 () {
    return(
        <motion.div
  initial={{opacity:0,scale:.3,paddingBottom:"100px"}}
  animate={{opacity:1,scale:1}}
  transition={{duration:.3,delay:.1,ease:'easeOut'}}>

<HStack mb="15"  justifyContent="space-between">
<MySeo title="Das Schicksal der Oberweseler und Bacharacher Juden" description="Fotodokumentation, Biographien, Animationen" />

<Box>
     
<StaticImage
  src="../images/solo/Walter-Meyer-Loeb-Pass.jpg"
  alt="Passbild"
  layout='constrained'
  height={450}
  width={500}
  />
</Box>
<Box>
<StaticImage
  src="../images/bacharach/17.jpg"
  alt="Fuhrgeschäft in Bacharach"
  layout='constrained'
  height={450}
  width={500}
/>

</Box>

</HStack>


        <Box >
            <Heading variant="Titel">
            Das Schicksal der Bacharacher<br/>   und Oberweseler Juden
            </Heading>
            <SimpleGrid columns={[1,1,2]} gap={5}>
              <Box>
              <Text variant="solid">
            Während die Geschichte der deutschen Juden, ihre Verfolgung
und die Shoah längst erforscht wurde, übersah die deutsche Geschichtswissenschaft noch immer die jüdische Lokalgeschichte. Das traf auch auf die Gemeinden am Mittelrhein
und im Hunsrück zu, obwohl die Region durchaus überregional bedeutende Juden hervorgebracht hatte (Albert Kahn aus Rhaunen etwa, der schon zu Beginn des 20. Jahrhunderts zum Vorreiter der amerikanischen Industriearchitektur avancierte, oder Alfred Gottschalk aus Oberwesel, der als Kind einer geflüchteten Familie zu einem führenden Vertreter des Reformjudentums wurde). Auch gilt es, das Gedenken an
engagierte jüdische Frauen (wie Henriette Gerson, geb. Trum,
die als Mitglied des Stadtrats in Oberwesel an der Gestaltung
der Stadt mitwirkte) aufrechtzuerhalten.
            </Text>
              <Link to="/gedenkliste"><Text  _hover={{color:"blue.300"}}  variant="solid"> &rarr; Unvollständige Gedenkliste</Text> </Link>
              </Box>
           
           <Box> <Text variant="solid">
            Die Ausstellung findet mit lokalem Bezug in Bacharach und in Oberwesel statt. Sie will mit ihren Schautafeln die Biografien der Menschen und Familien in den Vordergrund rücken, die in jeweils einem der Orte gelebt und ihren Beitrag zur damaligen und heutigen Gesellschaft geleistet haben.
            </Text></Box>
           
            </SimpleGrid>
            <SimpleGrid columns={[1,1,2]} gap={4}>
              <Box>
              
              <Link to="/oberwesel"><Text  _hover={{color:"blue.300"}}  variant="solid"> &rarr; Zum Schicksal der Juden in Oberwesel</Text> </Link>
                  
                
              </Box>
              <Box>
              <Link to="/bacharach"><Text _hover={{color:"blue.300"}}  variant="solid"> &rarr; Zum Schicksal der Juden in Bacharach</Text> </Link>
                 
                 
              </Box>
            </SimpleGrid>
        </Box>
        </motion.div>
    )
}